import "index.scss"
import "jquery.meanmenu.min.js"
import "toggle-theme.js"
import "form.js"

// Import all JavaScript & CSS files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /\.(js|css)$/)
componentsContext.keys().forEach(componentsContext)

// mobile menu
jQuery(document).ready(function () {
    jQuery("header nav").meanmenu({
        meanScreenWidth: "646",
        meanMenuContainer: ".mobile-menu-section",
    });
});

console.info("Bridgetown is loaded!")
